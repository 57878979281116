<template>
	<section>
		<div class="banner"><div class="banner-con">Gorex · 账户注册</div></div>
		<div class="resgister-con">
			<p>注册成功！您已开通聚码账号！</p>
			<el-button type="primary" @click="handleSubmit">前往控制中心</el-button>
		</div>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
    	
        return {
        	url: ''
        }
    },
	methods: {
		handleSubmit(){
			// window.location.href = this.url;
			
			var host = window.location.host;
			url = 'https://'+host+'/v/juma/client/index/intro?v=1.2';
            window.location.href = url;
		}
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 233px;
		background-color: #F2F2F2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.banner-con{
		width: 695px;
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 24px;
		font-weight: bold;
		color: #333333;
	}
	.resgister-con{
		width: 699px;
		height: 676px;
		padding: 0 40px;
		border: solid 1px #E5E1E1;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.resgister-con p{
		font-size: 14px;
		font-weight: bold;
		color: #333333;
	}
	/deep/.el-button--primary{
		width: 190px;
		height: 36px;
		background-color: #169BD5;
		border-radius: 5px;
		font-size: 13px;
		color: #fff;
		border-color: #169BD5;
		margin-top: 50px;
	}
</style>